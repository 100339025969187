import { Badge, Group, Stack, Text } from '@mantine/core';

import { useTranslation } from '@apple/lib/i18next';
import type { LocationProgram } from '@apple/features/location';

export interface LocationProgramListProps {
	programs: LocationProgram[];
	vertical?: boolean;
}

export function LocationProgramList({ programs, vertical = false }: LocationProgramListProps) {
	const { t } = useTranslation('shop');
	const BadgeGroup = vertical ? Stack : Group;
	const alignment = vertical ? 'end' : 'start';

	return (
		<Stack data-testid='programs' gap='2' justify='start' align={alignment}>
			<Text size='sm'>{t('steps.locations.programs.label')}</Text>
			<BadgeGroup gap='3' align={alignment} wrap='wrap'>
				{programs?.map((program, i) => (
					<Badge key={i} color='blue.7' size='sm' variant='light'>
						{program.customerGroupName}
					</Badge>
				))}
			</BadgeGroup>
		</Stack>
	);
}
